<template lang="pug">
    article#cards
        .wrapper
            .galeria(ref="galeria")
                ul
                    li(v-for="item, index in lista", ref="cards")
                        // h4 {{ item.data.mes }} - {{ item.data.ano }}
                        h4(v-if="item.data") {{ item.data.ano }}
                        h3 {{ item.texto.titulo }}

                        .conteudo(v-if="item.texto")
                            p(v-for="texto in item.texto.textos", v-html="texto")

                        figure
                            img(:src="item.imagem", :alt="`${item.texto.titulo}`")

</template>

<script>
export default {
    props: {
        lista: {
            required: true,
            type: Array
        },
        ativo: {
            required: true,
            type: Number
        }
    },
    mounted() {
        this.$refs.galeria.scrollLeft = (this.$devinho.getWidth(this.$refs.cards[0]) + (this.$devinho.getMargin(this.$refs.cards[0]) * 2)) * this.$props.ativo;
    }
}
</script>

<style lang="stylus" scoped src="./Cards.styl"></style>